import * as React from "react";
import type { HeadFC } from "gatsby";
// import Header_Layout from "../components/layouts/Header_Layout";

// import LineGraph from "../components/custom_build_analytics/LineGraph";
// import BarGraph from "../graphs/BarGraph";
// import PieChart from "../components/custom_build_analytics/PieChart";
// import { Grid } from "@mui/material";
// import ScatterPlot from "../components/parts/ScatterPlot";
// import RadarChart from "../components/custom_build_analytics/RadarChart";

const BlogPage = () => {
  return;
  // return (
  //   <Header_Layout>
  //     <Grid container sx={{ width: "100%", height: "100%" }}>
  //       <Grid item>
  //         <RadarChart
  //           title={"Component Price Distribution Compared to Standard"}
  //           width={500}
  //           height={425}
  //         />
  //       </Grid>
  //       <Grid item>
  //         <LineGraph
  //           title={"BitBot valuation compared with BitLot composite valuation"}
  //           width={800}
  //           height={425}
  //         />
  //       </Grid>
  //       <Grid item>
  //         <BarGraph
  //           title={"Newness of each part compared to build age"}
  //           width={800}
  //           height={425}
  //         />
  //       </Grid>
  //       <Grid item>
  //         <PieChart
  //           title={"Component Price Distribution"}
  //           width={400}
  //           height={425}
  //         />
  //       </Grid>
  //       <Grid item>
  //         <ScatterPlot
  //           title={"CPU price to clock speed ratio"}
  //           width={400}
  //           height={425}
  //         />
  //       </Grid>
  //     </Grid>
  //   </Header_Layout>
  // );
};

export default BlogPage;

export const Head: HeadFC = () => <title>Graphs - BitLot.app</title>;
